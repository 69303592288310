
#header{
    background:linear-gradient(to right,#bfcea6,#70BC5B);
    padding: 8px 16px;
}

.header_container{
    /* position: absolute; */
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
}

.header_user{
    /* position: absolute; */
    left:0px;
    display: grid;
    grid-template-columns: 20px auto;
    justify-content: left;
}

.header_user img {
    width: 20px;
    height: 20px;
    max-width: 100%;
    max-height: 100%
}

.header_address{
    /* position: absolute; */
    right:0px;
    display: grid;
    grid-template-columns: 20px auto 20px;
    justify-content: end;
}

.header_address img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%
}

.search_filter{
    /* position: absolute; */
    /*right:0px;*/
    display: grid;
    grid-template-columns: 130px 10px auto;
    /*justify-content: end;*/
}


#postlist_page{
    padding: 8px 16px 4px;/*0.1rem 0.4rem 0.2rem;*/
}

/* #postlist_page  */
#query_div{
    width:100%;
    align-items: center;
    text-align:center;
    display: grid;
    grid-template-columns: 80% 20% ;
    grid-gap: 5px;
    margin-bottom: 0.2rem;
}

.back_top_div {
    position: absolute;
    z-index: 999;
    right: 20px;
    bottom: 86px;
}
.back_top_button {
    background:url(../icon/top.svg) no-repeat center center;
    background-size: 40px 40px;/*0.8rem 1rem; */
    height:40px;
    width:40px;
    /* 在这设置图标大小，不设置就是svg默认的宽高 */
}

/*  下拉选项字体大小，不生效
.ant-select-dropdown{
   font-size: 0.4rem;
} */
.write_button_div {
    position: absolute;
    z-index: 999;
    right: 20px;
    bottom: 135px;
}
.write_button {
    background:url(../icon/write.svg) no-repeat center center;
    /* background-image: url(../../icon/write.svg)  ; */
    display: inline-block;
    /* background-repeat: no-repeat; */
    background-size: 40px 40px;/*0.8rem 1rem; */
    height:40px;
    width:40px;
    /* 在这设置图标大小，不设置就是svg默认的宽高 */
}

.post_div{
    width:100%;
    line-height: 1;
    color: #121212;
}

.post_title{
    font-size: 16px;/*0.4rem;*/
    padding-top: 8px;
    padding-bottom: 8px;/*0.5rem;*/
    color: #121212;
    /* font-weight: bold; */
}
.post_article{
    font-size: 16px;/*0.34rem;*/
    line-height: 20px;   /*行高*/
    /*letter-spacing: 1px; 字间距*/
    padding-top: 4px;
    padding-bottom: 14px;
    padding-left: 8px;
    padding-right: 8px;
}

.post_remark{
    width:100%;
    font-size: 16px;/*0.2rem; */
    color: #363636;
    margin-bottom: 8px;/* 0.5rem;*/
    align-items: center;
    text-align:left;
    display: grid;
    grid-template-columns: 4% 45% 45%;
    grid-gap: 5px;
}

.house_svg {
    background-image: url(../icon/house.svg)  ;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 12px 12px;/*0.4rem 0.4rem;  */
    height:12px;/*0.4rem;  */
    width:12px;/*0.4rem;  */
}

.portrait_and_time{
    width:100%;
    display: grid;
    align-items: center;
    text-align:right;
    grid-template-columns: auto auto;
}

/*.search_bar {*/
/*    display: grid;*/
/*    grid-template-columns: auto 5px 40px;*/
/*    justify-content: normal;*/
/*}*/

/* 详情页标题栏 */
#postlist_page .am-list-header{
    padding: 0px;
}