.detail_author{
  width:100%;
  // height: 0.4rem;
  font-size: 12px;//0.2rem; 
  color: 	#363636;
  padding-top: 8px;//0.5rem;
  padding-bottom: 8px;//0.5rem;
  align-items: center;
  text-align:left;
  display: grid;
  grid-template-columns: 28px 80%;
  grid-gap: 0px;
}
.detail_author_reply{
   width:100%;
   // height: 0.4rem;
   font-size: 12px;//0.2rem;
   color: 	#363636;
   padding-top: 8px;//0.5rem;
   padding-bottom: 8px;//0.5rem;
   align-items: center;
   text-align:left;
   display: grid;
   grid-template-columns: auto auto auto;
   place-content: flex-start;
   grid-gap: 2px;
}
.detail_bottom{
  width:100%;
  font-size: 12px;/*0.2rem; */
  color: #363636;
  margin-bottom: 8px;/* 0.5rem;*/
  align-items: center;
  text-align:left;
  display: grid;
//     grid-template-columns: 3% 40% 10% 10% 10% 10% 10%;
    grid-template-columns:3% 40% auto;
    grid-gap: 2px;
}
.detail_remark{
  width:100%;
  font-size: 12px;/*0.2rem; */
  color: #363636;
  margin-bottom: 8px;/* 0.5rem;*/
  align-items: center;
  text-align:left;
  display: grid;
  place-content: flex-end;
//     grid-template-columns: 3% 40% 10% 10% 10% 10% 10%;
    grid-template-columns: auto auto auto auto auto auto;
    //grid-gap: 16px;
}



.postref_with_count{
  align-items: center;
  grid-template-columns: auto auto;
  display: flex;
  place-content: flex-start;
}

.remark_svg_style{
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 30px 16px;/*1rem 0.5rem;  */
    height:16px;/*0.5rem;*/
    width:30px;/*1rem;*/
}
.house_svg {
  background-image: url(../icon/house.svg)  ;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 12px 12px;/*0.4rem 0.4rem;  */
  height:12px;/*0.4rem;  */
  width:12px;/*0.4rem;  */
}
// .position_svg {
//     background-image: url(../icon/house.svg)  ;
//     display: inline-block;
//     background-repeat: no-repeat;
//     background-size: 0.4rem 0.5rem;  
//     height:0.5rem;
//     width:0.4rem;
//   }
  .delete_svg {
    background-image: url(../icon/delete.svg)  ;
    .remark_svg_style;
  }
  .sold_svg {
    background-image: url(../icon/sold.svg)  ;
    .remark_svg_style;
  }
  .sold_svg_checked {
    background-image: url(../icon/sold_checked.svg)  ;
    .remark_svg_style;
  }

  .collect_svg {
    background-image: url(../icon/collect.svg)  ;
    .remark_svg_style;
  }
  .collect_svg_checked {
    background-image: url(../icon/collect_checked.svg)  ;
    .remark_svg_style;
  }

.reply_svg {
    background-image: url(../icon/reply.svg)  ;
    .remark_svg_style;
  }

  .chat_svg {
    background-image: url(../icon/chat.svg)  ;
    .remark_svg_style
  }

.edit_svg {
  background-image: url(../icon/edit.svg)  ;
  .remark_svg_style
}

  .reply_div{
    z-index: 100;
    position:fixed;
    // width: 100%;
    left: 0.4rem;
    right: 0.4rem;
    bottom: 2rem;
    border:0.01rem solid #121212;
    border-radius: 6px; 
    padding:2px; //为了圆角不断裂
    background-color: white; //为了不显出下层div内容
  }

@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;