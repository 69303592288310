.my-list .spe .am-list-extra {
    flex-basis: initial;
  }

   .am-list-item .am-list-line .am-list-content{
    font-size: 16px;
  }

  .my-radio .am-radio {
    padding: 2.5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 5px;
  }

  .my_avatar{
    margin-top: 10px;
    margin-left: 30px;
    display: grid;
    grid-template-columns: 60px  auto;
    align-items: center;
    font-size: 16px;
}

  .flexWrapper {
    display: flex;
  }
  
  .flex-item0 {
    /* flex-grow:1; */
    flex:0 0 10%
  }
  .flex-item1 {
    /* flex-grow:1; */
    flex:0 0 18%
  }

  .flex-item2 {
    /* flex-grow:3; */
    flex:0 0 70%
  }

  .my_svg_style{
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 1rem 0.5rem;  
    height:0.5rem;
    width:1rem;
}

.msg_svg {
  background-image: url(../icon/my/msg.svg)  ;
  .my_svg_style;
}
@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;