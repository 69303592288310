.am-navbar{
    background-color: #70BC5B;
}

.share_pic { 
        width:100%;
        font-size: 0.2rem; 
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        align-items: center;
        text-align:left;
        display: grid;
        grid-template-columns: 15% 80%;
        grid-gap: 2px;  
}

.leaf_icon {    
    background-image: url(../icon/leaf.svg)  ;
    background-repeat: no-repeat;
    background-size: 1.4rem 2.5rem;  
    height:2.5rem;
    width:1.4rem;
}
@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;