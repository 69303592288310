
.adm-dropdown {
  height: 24px;//40px;//1rem!important;
  background-color: rgb(0 0 0 / 0%);
}

.adm-dropdown-item {
  border-radius: 10px!important;
  background-color: #FFFFFF//rgb(100 200 100)//rgb(0 181 120);
}

.adm-dropdown-item-title {
  left: -5px;
  height: 24px;
  padding: 1px!important;
}

.adm-dropdown-item-title:after{
  right: -10px!important;
}

.adm-dropdown-item .adm-dropdown-item-title span {
  color: black;
}

.adm-tag {
  color: #000000;
}

.adm-tag-round {
  color: black;
}

@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;