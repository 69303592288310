.chat_item_receive{
    width:100%;
    font-size: 0.4rem; 
    margin-top: 0.3rem;
    margin-bottom: 0.1rem;
    align-items: center;
    text-align:left;
    display: grid;
    grid-template-columns: 2% 10% 75%;
    grid-gap: 2px; 
}

.chat_item_send{
    width:100%;
    font-size: 0.4rem;
    margin-top: 0.3rem;
    margin-bottom: 0.1rem;
    align-items: center;
    text-align:left;
    display: grid;
    grid-template-columns: 10% 75% 10%;
    grid-gap: 2px;
}

.chat_refer_receive{
    display: grid;
    grid-template-columns: 12.5% auto;
    justify-content: left;
}

.chat_refer_send{
    display: grid;
    grid-template-columns: auto 15%;
    justify-content: right;
}