#area_div{
    width:100%;
    align-items: center;
    text-align:center;
    display: grid;
    grid-template-columns: 80% 20% ;
    grid-gap: 5px;
    margin-bottom: 0.2rem;
}
/* 输入框高度 */
 .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;//40px;//1rem!important;

}
/* X按钮大小 */
 .ant-select-clear svg{
    width: 24px;//32px;//0.8rem!important;
    height: 24px;//32px;//0.8rem!important;
}
/*X按钮位置*/
 .ant-select-clear {
    top:30%!important;
}
 .ant-select-auto-complete .ant-select-clear {
    right: 16px;//0.8rem;
}
/*输入框字体大小*/
 .ant-select-selection-search{
    font-size: 16px;//0.4rem;
}


/*选择小区页*/
.current_location{
    margin-top: 10px;
    margin-left: 10px;
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
    font-size: 16px;
}

.current_location img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%
}

.current_view_city{
  margin-top: 10px;
  margin-left: 16px;
  display: grid;
  grid-template-columns: 20px 120px auto;
  align-items: center;
  font-size: 16px;
}

.current_view_city img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%
}

//Location.js中的NavBar
.am-navbar-title {
  color: black;
}
.am-icon {
  color: black;
}

/*选择小区页*/
.choose_area{
  margin-top: 10px;
  margin-left: 16px;
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;
  font-size: 16px;
}

.choose_area img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%
}

@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;