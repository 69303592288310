.write_div{
  /*margin:30px 30px;*/
}
 
  #article_input{
    font-size: 16px;
    color: black;
    padding: 0;
  }
  #title_input{
    font-size: 18px;
    color: black;
    padding: 0;
  }
 
  #position_div{
    display: flex; 
  }
  #position_div #icon_div {
    flex:0 0 20%;
    text-align:center;/*该层包裹的元素水平居中*/
  }
  #position_div #pos_div {
    flex:0 0 80%;
    text-align:center;/*该层包裹的元素水平居中*/
  }