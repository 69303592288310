.footer_box{
  //height: 1.6rem;
  a{color: #000;}
}

.footer_wrap{
  position: fixed;
  z-index: 99999;
  left: 0;
  //bottom: 0.01rem;
  width: 100%;
  height: 80px;
  @if (`navigator.userAgent.toLowerCase().includes('windows')`) {
    height: 120px
  }
  background-color: #fff;
  border-top:1px solid #f5efef;
  display: flex;
  .footer_item{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .iconfont{
      font-size:.5rem
    }
    .footer_item_word{
      display: block;
      margin-top: .02rem;
    }
    &.active{
      background: -webkit-linear-gradient(left, #0af , #0085ff);     /* 背景色渐变 */
      -webkit-background-clip: text;         /* 规定背景的划分区域 */
      // -webkit-text-fill-color: transparent;  /* 防止字体颜色覆盖 */
    }
  }
}

.active {
    .footer_item_word{      
    // color:#F37A2A!important;
    color:#0e932e!important;
  }
  .svgbutton_sell{
    background-image: url(../icon/sell_active.svg) ;
    .svg_style;
  }
  .svgbutton_my {
    background-image: url(../icon/my_active.svg) ;
    .svg_style;
  }
  .svgbutton_more {
    background-image: url(../icon/more_active.svg); 
    .svg_style;
  }
  .svgbutton_experience {
    background-image: url(../icon/experience_active.svg);
    .svg_style;
  }
}

.svg_style{
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 0.8rem 0.8rem;
  height:0.8rem;
  width:0.8rem;
}

.svgbutton_sell {
  background-image: url(../icon/sell.svg)  ;
  .svg_style;
}

.svgbutton_my {
  background-image: url(../icon/my.svg);
  .svg_style;
}
.svgbutton_more {
  background-image: url(../icon/more.svg); 
  .svg_style;
}
.svgbutton_experience {
  background-image: url(../icon/experience.svg);
  .svg_style;
}

.beian-item {
    left: 0;
    position: fixed;
    text-align: center;
    bottom: 2px;
    /* top 0 就是顶部 */
    width: 100%;
    z-index: 1000000;
    background: #ffffff;
    display: flex;
    justify-content: center;
    color: #ffff;
}
@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;