.user-avatar-upload{
    position: relative;
    left: 50%;
    width: 3rem;
    height: 3rem;
    margin: 0.2rem 0;
    transform: translate3d(-65%, 0, 0);
    // 头像图片
    .user-avatar{
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    // 图片上传
    .am-image-picker{
      position: absolute;
      width: 100%;
      height: 100%;
      .am-image-picker-list{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        .am-flexbox{
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          border-radius: 50%;
          overflow: hidden;
          // 不显示其他
          .am-flexbox-item{
            display: none;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border-radius: 50%;
          }
          // 只显示上传
          .am-flexbox-item:nth-child(1){
            display: block;
            input{
              width: 100%;
              height: 100%;
            }
          }
          .am-image-picker-upload-btn{
            border: 0;
            // 设置透明背景以便看底层 img
            background-color: transparent!important;
            // 去掉上传的 + 号
            &:before,&:after{
              content: none!important;
            }
          }
        }
      }
    }
  }

.am-list-header {
  padding: 5px 10px 2px 15px;
}
//.am-list-item.am-input-item {
//  height: 32px;
//}

@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;