.chat_operate{
    width:100%;
    font-size: 0.2rem; 
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    align-items: center;
    text-align:center;
    display: grid;
    grid-template-columns: 10% 35% 10% 35% 10%;
    // grid-gap: 10%; 
}
@brand-primary: #F37A2A;@brand-primary-tap: #F37A2A;@text-color: #000;