
.replyDiv {
    position: fixed;
    width: 90%;
    height: 60%;
    padding: 0.5rem;
    background-color: #ebebef;
    border: 3px solid #73AD21;
    /* color: white; */
    bottom: 1rem;
    margin-left:5%;
    z-index: 10;
    -webkit-animation: mymove 0.2s  ; /* Chrome, Safari, Opera */
    --animation: mymove 0.2s  ;
}


.svgbutton {
  background-image: url(../../icon/query.svg)  ;
  /* background-image: url(./1.png)  ; */
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 40px 40px; 
  height:1rem;
  width:1rem;
  /* 在这设置图标大小，不设置就是svg默认的宽高 */
}

/*好像不需要特殊专门配置*/
#msg_page {
  /* position: absolute;
  width: 100%;
  height:100%;
  top:0.1rem; */
  /* bottom: 1rem; */
  /* overflow-y:auto */
}

#msg_page .am-list-view-scrollview{
  height:15rem!important;
  /* top:50px;
  bottom:10px; */
}

#msg_page #chat_div{
  display: flex;
  height:1.8rem;
  color: #000;
  padding: 2px;
  align-items: center;
}

#msg_page #chat_div #user_image{
  flex:0 0 20%;
  text-align:center;/*该层包裹的元素水平居中*/
}
#msg_page #chat_div #user_image img{
    max-width: 100%;
    max-height:100%;  
}
#msg_page #chat_div #chat_info{
  flex:0 0 60%;
  width: 0;/*为了子元素不撑开*/

}
#msg_page #chat_div #chat_info #user{
  font-size: 16px;/*0.45rem;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#msg_page #chat_div #chat_info #msg{
  font-size: 14px;
  color:gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#msg_page #chat_div #chat_time{
  flex:0 0 20%;
  color:gray; 
}

.chatListDiv {
  position: fixed; 
  overflow-x: scroll;
  top:2rem;
  bottom: 2rem; 
}

/* 聊天时间样式 */
#chat_time_item .am-list-content {
  color: rgb(78, 80, 82);
  text-align: center;
  font-size: small;
}

/* 本人发送出去的消息 */
#chat_send_item .am-list-content {
  border-radius:4px;
  background-color:#00EE00;
}

.chat_page {
  grid-template-rows: auto 100px;
  place-content: stretch;
  position: relative;
}

.chat_send_text_and_pic_bar {
  width:100%;
  display: grid;
  grid-template-rows: auto 100px;
  place-content: stretch;
  position: fixed;
  bottom: -53px;
  z-index: 10;
}

.chat_send_bar {
  width:100%;
  display: grid;
  grid-template-columns: 8px auto 65px;
  place-content: stretch;
  /*position: fixed;*/
  /*bottom: 0px;*/
  /*left: 0px;*/
  z-index: 10;
  background: rgb(245,245,245);
}

.chat_send_picture_button {
  width:100%;
  display: grid;
  grid-template-columns: 10px auto 10px;
  place-content: stretch;
}

.chat_input_area {
  border-radius: 10px;
  /*border:1px solid #ccc; !*输入框边框*!*/
  /*width: 98%;*/
  left: 2%;
  height: 80%;
  top: 10%;
  min-height: auto;
}

/* 本人接收的消息 */
#chat_receive_item .am-list-content { 
  border-radius:4px;
  background-color:	#F5FFFA;
}
 /* 本人头像 */
#chat_send_item .am-list-extra{
  flex-basis: 10%;
}

#chat_send_item .am-list-extra img{
  width: 30px;
  height: 30px;
}
/* .am-tab-bar { 
  position: fixed;
  bottom: 0;
  width: 100%;
  height: inherit;  
} */

#chat-page .sticky-header {
  /*使标题始终在顶部*/
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
}

#chat-page{
  position:relative;
  height:100%; 
  }
/*聊天记录div不要盖到消息发送层*/
#chat-page  .am-list  {
  /* height: 15.5rem; */
  /* position: fixed; */
  /* overflow: auto; */
  /* bottom: 2rem;  */
  /* top:4rem; 不起作用*/
  /* marginTop: 40 */
  /*我加的,为了list不到底*/
}
 

 
#chat-page .chat-me .am-list-content {
  /* padding-right: 15px; */
  
  text-align: left;
}

#chat-page .am-tab-bar {
  /*使导航始终在底部*/
  position: fixed;
  bottom: 0.1rem;
  height: 2rem; 
  /* width: 100%; */
  /* background: rgb(255, 255, 255); */
  z-index: 99;
}

#chat-page .stick-top {
  /*固定在顶部*/
  /* z-index: 10;
  position: fixed;
  top: 0;
  width: 100%; */
}
  
 